import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { uploadFiles } from "../constants/ApiCall";

class QuotationPdfService {
    static DEBUG = true;
    
    static log(message, data = null) {
        if (this.DEBUG) {
            console.log(`[PDF Service] ${message}`, data || '');
        }
    }

    static handleImageErrors(element) {
        // Handle CORS issues with images
        const images = element.getElementsByTagName('img');
        for (let img of images) {
            img.crossOrigin = "anonymous";
            img.style.filter = 'brightness(0.8) contrast(1.2)';
            // Add error handler to replace failed images
            img.onerror = function() {
                this.style.display = 'none';
            };
        }
    }

    static forceBlackColors(element) {
        const elementsToProcess = element.getElementsByTagName('*');
        const importantStyles = [
            'color: rgb(0, 0, 0) !important',
            '-webkit-text-fill-color: rgb(0, 0, 0) !important',
            'text-fill-color: rgb(0, 0, 0) !important',
            'print-color-adjust: exact !important',
            '-webkit-print-color-adjust: exact !important',
            'font-weight: 400 !important',
            'text-rendering: geometricPrecision !important',
            '-webkit-font-smoothing: antialiased !important',
            'opacity: 1 !important'
        ];

        for (let el of elementsToProcess) {
            // Force black color via multiple methods
            el.style.cssText = importantStyles.join(';') + ';' + el.style.cssText;
            
            // Set direct properties
            el.style.setProperty('color', '#000000', 'important');
            el.style.setProperty('-webkit-text-fill-color', '#000000', 'important');
            
            // Handle special cases
            if (el.tagName === 'SVG' || el.tagName.startsWith('SVG')) {
                el.setAttribute('fill', '#000000');
                el.setAttribute('color', '#000000');
            }
        }
    }

    static preparePDFStyles() {
        return `
            * {
                color: rgb(0, 0, 0) !important;
                -webkit-text-fill-color: rgb(0, 0, 0) !important;
                text-fill-color: rgb(0, 0, 0) !important;
                print-color-adjust: exact !important;
                -webkit-print-color-adjust: exact !important;
                font-weight: normal !important;
                text-rendering: geometricPrecision !important;
                -webkit-font-smoothing: antialiased !important;
            }
            
            h1, h2, h3, h4, h5, h6 {
                color: rgb(0, 0, 0) !important;
                font-weight: 600 !important;
            }

            p, span, div, td, th, li {
                color: rgb(0, 0, 0) !important;
                font-weight: 400 !important;
            }

            .pdf-content {
                filter: brightness(0.8) contrast(1.2) !important;
            }
            
            @media print {
                * { 
                    color: rgb(0, 0, 0) !important;
                    print-color-adjust: exact;
                    -webkit-print-color-adjust: exact;
                }
            }
        `;
    }

    static async exportPDF(element, data) {
        try {
            this.log('Starting PDF generation');
            
            // 1. Prepare the element
            const container = document.createElement('div');
            container.style.position = 'fixed';
            container.style.top = '0';
            container.style.left = '-9999px';
            container.style.width = '210mm';
            container.style.height = 'fit-content';
            container.style.minHeight = null; // Remove minHeight
        
            container.style.background = 'white';
            
            // 2. Clone and enhance the element
            const clone = element.cloneNode(true);
            
            // 3. Add force black styles
            const styleElement = document.createElement('style');
            styleElement.textContent = this.preparePDFStyles();
            container.appendChild(styleElement);
            container.appendChild(clone);
            document.body.appendChild(container);
            
            // 4. Force black colors and handle images
            this.forceBlackColors(clone);
            this.handleImageErrors(clone);
            
            // 5. Generate PDF with optimized settings
            const pdfOptions = {
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
                compress: true,
                precision: 16
            };
            
            const canvasOptions = {
                scale: 2,
                useCORS: true,
                allowTaint: true,
                backgroundColor: '#ffffff',
                removeContainer: true,
                imageTimeout: 15000,
                logging: this.DEBUG,
                onclone: (clonedDoc) => {
                    const content = clonedDoc.querySelector('.pdf-content');
                    if (content) {
                        this.forceBlackColors(content);
                    }
                }
            };
            
            try {
                // 6. Create PDF
                const pdf = new jsPDF(pdfOptions);
                
                // 7. Generate canvas
                const canvas = await html2canvas(clone, canvasOptions);
                
                // 8. Calculate dimensions
                const imgWidth = 210;
const imgHeight = (canvas.height * imgWidth) / canvas.width;
const pageHeight = 297;

                
                // 9. Add first page
                pdf.addImage(
                    canvas.toDataURL('image/jpeg', 1.0),
                    'JPEG',
                    0,
                    0,
                    imgWidth,
                    imgHeight,
                    undefined,
                    'FAST'
                );
                
                // 10. Handle multiple pages
                let heightLeft = imgHeight;
let position = 0;
let page = 0;
                
while (heightLeft > 0) {
    // Get portion of the canvas for this page
    const currentHeight = Math.min(heightLeft, pageHeight);
    
    if (page > 0) {
        pdf.addPage();
    }
    
    pdf.addImage(
        canvas.toDataURL('image/jpeg', 1.0),
        'JPEG',
        0,
        position,
        imgWidth,
        imgHeight,
        undefined,
        'FAST'
    );
    
    heightLeft -= pageHeight;
    position -= pageHeight;
    page++;
}
                
                // 11. Create and upload file
                const pdfBlob = pdf.output('blob');
                const pdfFile = new File([pdfBlob], `quotation_${data._id}.pdf`, { type: 'application/pdf' });
                
                const formData = new FormData();
                formData.append('quotation', pdfFile);
                formData.append('type', 'quotation');
                formData.append('quotation', data._id);
                
                await uploadFiles(formData);
                
                this.log('PDF generated and uploaded successfully');
                return true;
                
            } catch (error) {
                this.log('PDF generation error:', error);
                throw error;
            } finally {
                // Cleanup
                if (container && container.parentNode) {
                    container.parentNode.removeChild(container);
                }
            }
            
        } catch (error) {
            this.log('PDF service error:', error);
            throw error;
        }
    }
}

export default QuotationPdfService;