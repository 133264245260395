import React, { useEffect, useState, useCallback, memo, useMemo, useRef } from "react";
import _ from "lodash";
import LoadingOverlay from "react-loading-overlay";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import queryString from "query-string";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Header from "../Components/Header";
import { getContent } from "../constants/ApiCall";
import Footer from "../Components/Footer";
import { FileText, Film, ChevronLeft, ChevronRight } from "lucide-react";

const BUCKET_URL = "https://all-user-version-4.s3.us-east-1.amazonaws.com/";
const pdfjsWorkerPath = `https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    background: "#FFF",
  },
  main: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  contentSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  viewerWrapper: {
    position: "relative",
    width: "100%",
    background: "#EBEFF4",
    height: "70vh", // Fixed height instead of auto/dynamic
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px", // Added rounded corners
    overflow: "hidden", // Ensure content doesn't overflow rounded corners
  },
  viewerContainer: {
    position: "relative",
    flex: 1,
    width: "100%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mediaContent: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  // Specific styles for PDF
  pdfWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .react-pdf__Document": {
      flex: 1,
      overflow: "auto",
    },
  },
  thumbnailSection: {
    background: "#EBEFF4",
    padding: "10px 0",
    borderRadius: "8px", // Added rounded corners
    overflow: "hidden", // Contains the scroll
  },
  thumbnailStrip: {
    display: "flex",
    overflowX: "auto",
    gap: "8px",
    padding: "0 16px",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "2px",
    },
  },
  thumbnail: {
    width: "80px",
    height: "80px",
    flexShrink: 0,
    border: "2px solid transparent",
    borderRadius: "4px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    cursor: "pointer",
    transition: "border-color 0.2s",
  },
  thumbnailActive: {
    border: "2px solid #4a90e2",
  },
  navButton: {
    position: "absolute",
    width: "40px",
    height: "40px",
    background: "rgba(0, 0, 0, 0.5)",
    border: "none",
    borderRadius: "50%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 10,
    transition: "opacity 0.2s",
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
    "&:hover": {
      opacity: 1,
    },
  },
  descriptionSection: {
    padding: "20px",
    background: "#EBEFF4",
    borderRadius: "8px",
    "& p": {
      margin: 0,
      fontSize: "14px",
      lineHeight: "1.6",
      whiteSpace: "pre-line", // This handles line breaks simply
    },
  },
  userSection: {
    background: "#EBEFF4",
    padding: "20px",
    borderRadius: "4px",
    marginTop: "auto",
    "& .circle-image": {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      objectFit: "cover",
    },
  },
  footerContainer: {
    marginTop: "auto",
    width: "100%",
  },
}));

class ViewerErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
   
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4 text-center">
          <h3>Unable to display content</h3>
          <button
            onClick={() => this.setState({ hasError: false })}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Try Again
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

const FileViewer = ({ file }) => {
  const classes = useStyles();
  const [loadError, setLoadError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const fileType = file?.uri?.split(".").pop().toLowerCase();
  const [pdfUrl, setPdfUrl] = useState(null);
  const imageRef = useRef(null);


  const debouncedSetLoading = useCallback(
    _.debounce((value) => {
      setIsLoading(value);
    }, 150), // 150ms debounce
    []
  );
  useEffect(() => {
    return () => {
      if (imageRef.current) {
        imageRef.current.src = ''; // Clear src on unmount
      }
      debouncedSetLoading.cancel(); // Cancel any pending debounced calls
    };
  }, [file?.uri]);
  useEffect(() => {
    const logContainerDimensions = () => {
      const container = document.querySelector(`.${classes.viewerContainer}`);
      if (container) {
        
      }
    };
  
    logContainerDimensions();
    window.addEventListener('resize', logContainerDimensions);
    
    return () => window.removeEventListener('resize', logContainerDimensions);
  }, []);

  





  
  

  useEffect(() => {
    if (typeof window !== 'undefined' && window.pdfjsLib) {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorkerPath;
     
    }
  }, []);

 

  useEffect(() => {
    const fetchPdf = async () => {
      if (fileType === 'pdf' && file?.uri) {
        
        try {
          const response = await fetch(file.uri, {
            headers: {
              'Accept': 'application/pdf'
            }
          });
          
          if (!response.ok) throw new Error('Failed to fetch PDF');
          
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
          setIsLoading(false);
        } catch (error) {
          console.error('PDF fetch error:', error);
          setLoadError(error);
          setIsLoading(false);
        }
      }
    };

    fetchPdf();
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
        setPdfUrl(null);
      }
    };
  }, [file?.uri, fileType]);

  

useEffect(() => {
  if (imageRef.current) {
    console.log('[FileViewer] Image element reference:', {
      element: imageRef.current,
      complete: imageRef.current.complete,
      naturalDimensions: {
        width: imageRef.current.naturalWidth,
        height: imageRef.current.naturalHeight
      },
      computedStyle: window.getComputedStyle(imageRef.current),
      timestamp: new Date().toISOString()
    });
  }
}, []);

  // Log when loading state changes
  useEffect(() => {
    console.log("Loading state changed:", {
      isLoading,
      hasError: !!loadError,
      errorMessage: loadError?.message,
      timestamp: new Date().toISOString()
    });
  }, [isLoading, loadError]);

  useEffect(() => {
    setIsLoading(true);
    setLoadError(null);
    
    const timeoutId = setTimeout(() => {
      if (isLoading) {
        handleLoadError(new Error(`Loading ${fileType} timed out`));
      }
    }, 30000); // 30 second timeout
    
    return () => {
      clearTimeout(timeoutId);
    };
  }, [file?.uri]);

  if (!file?.uri) {
    return <div>No file selected</div>;
  }

 const handleLoadSuccess = () => {
    console.log('[DEBUG-CRITICAL] Image load success triggered:', {
      timestamp: new Date().toISOString(),
      fileUrl: file?.uri,
      imageElement: document.querySelector(`.${classes.mediaContent}`),
      elementProperties: {
        complete: document.querySelector(`.${classes.mediaContent}`)?.complete,
        naturalWidth: document.querySelector(`.${classes.mediaContent}`)?.naturalWidth,
        naturalHeight: document.querySelector(`.${classes.mediaContent}`)?.naturalHeight,
        displayedWidth: document.querySelector(`.${classes.mediaContent}`)?.offsetWidth,
        displayedHeight: document.querySelector(`.${classes.mediaContent}`)?.offsetHeight,
      }
    });
    setIsLoading(false);
  };

  const verifyResource = async (uri) => {
    try {
      const response = await fetch(uri, { method: 'HEAD' });
      if (!response.ok) throw new Error(`Resource not available: ${response.status}`);
      return true;
    } catch (error) {
      handleLoadError(error);
      return false;
    }
  };

  const handleLoadError = (error) => {
    console.log('[DEBUG-CRITICAL] Image load error triggered:', {
      timestamp: new Date().toISOString(),
      error,
      fileUrl: file?.uri,
      elementExists: !!document.querySelector(`.${classes.mediaContent}`)
    });
    setLoadError(error);
    setIsLoading(false);
  };

  console.log('[DEBUG-CRITICAL] Pre-render state:', {
    timestamp: new Date().toISOString(),
    willRenderImage: ["jpg", "jpeg", "png", "gif"].includes(fileType),
    fileType,
    fileUrl: file?.uri,
  });

  const renderContent = () => {
    console.log('[DEBUG-CRITICAL] Rendering content:', {
      timestamp: new Date().toISOString(),
      fileType,
      fileUrl: file?.uri,
    });
    if (["jpg", "jpeg", "png", "gif"].includes(fileType)) {
      console.log('[DEBUG-CRITICAL] Creating image element');
      
      // Create test image to verify URL
      const testImg = new Image();
      testImg.onload = () => console.log('[DEBUG-CRITICAL] Test image loaded successfully:', file.uri);
      testImg.onerror = () => console.log('[DEBUG-CRITICAL] Test image failed to load:', file.uri);
      testImg.src = file.uri;

      return (
        <img
          key={file.uri} // Force remount on URL change
          src={file.uri}
          alt="content"
          className={classes.mediaContent}
          onLoad={(e) => {
            console.log('[DEBUG-CRITICAL] Image onLoad fired:', {
              timestamp: new Date().toISOString(),
              event: e,
              complete: e.target.complete,
              naturalWidth: e.target.naturalWidth,
              naturalHeight: e.target.naturalHeight
            });
            handleLoadSuccess();
          }}
          onError={(e) => {
            console.log('[DEBUG-CRITICAL] Image onError fired:', {
              timestamp: new Date().toISOString(),
              event: e
            });
            handleLoadError(e);
          }}
          onLoadStart={() => console.log('[DEBUG-CRITICAL] Image load started')}
          onLoadedData={() => console.log('[DEBUG-CRITICAL] Image data loaded')}
          onAbort={() => console.log('[DEBUG-CRITICAL] Image load aborted')}
        />
  );
}

    if (["mp4", "webm"].includes(fileType)) {
      return (
        <video
  onLoadedData={handleLoadSuccess}
  onLoadedMetadata={() => console.log("Video metadata loaded")}
  onError={handleLoadError}
  onStalled={() => handleLoadError(new Error("Video loading stalled"))}
  controls
  className={classes.mediaContent}
>
  <source src={file.uri} type={`video/${fileType}`} />
</video>
      );
    }

    if (fileType === "pdf") {
      return (
        <div className={classes.pdfWrapper}>
          {isLoading && <div>Loading PDF...</div>}
          {loadError && <div>Error loading PDF: {loadError.message}</div>}
          {pdfUrl && (
            <DocViewer
              documents={[
                {
                  uri: pdfUrl,
                  fileType: "pdf",
                  fileName: file.uri.split("/").pop()
                }
              ]}
              pluginRenderers={DocViewerRenderers}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                },
                pdfZoom: {
                  defaultZoom: 1,
                  zoomJump: 0.2,
                },
                pdfVerticalScrollByDefault: true
              }}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#EBEFF4",
              }}
              onError={(error) => {
                console.error("DocViewer Error:", error);
                setLoadError(error);
              }}
            />
          )}
        </div>
      );
    }

    return <div>Unsupported file type</div>;
  };

  return (
    <div className={classes.viewerContainer}>
      {renderContent()}
      {isLoading && <div className="absolute inset-0 flex items-center justify-center bg-white/50">Loading...</div>}
      {loadError && <div className="absolute inset-0 flex items-center justify-center bg-white/50">Error loading file</div>}
    </div>
  );
};


function SharedFile(props) {
  const classes = useStyles();
  const { uniqueLinkId } = props.match.params;

  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [pageContent, setPageContent] = useState({});

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  // Auto-scroll thumbnail into view
  useEffect(() => {
    try {
      const thumbnail = document.querySelector(`[data-index="${activeIndex}"]`);
      if (thumbnail) {
        thumbnail.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
        console.log("Scrolled to thumbnail:", activeIndex);
      }
    } catch (err) {
      console.log("Thumbnail scroll error:", err);
    }
  }, [activeIndex]);

  // Load content
  useEffect(() => {
    const loadContent = async () => {
      try {
        const queryParams = queryString.parse(props.location.search);
        const response = await getContent(uniqueLinkId, queryParams.u || "");

        if (response?.data?.content) {
          const myFiles = response.data.content.files.map((file) => ({
            uri: BUCKET_URL + file.filePath,
          }));
          setFiles(myFiles);
          setPageContent(response.data);
        }
      } catch (error) {
        console.error("Content loading error:", error);
      } finally {
        setLoading(false);
      }
    };

    loadContent();
  }, [uniqueLinkId, props.location.search]);

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Loading files..."
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
        },
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.8)",
        }),
      }}
    >
      <div className={classes.root}>
        <CssBaseline />
        <Header
          organization={pageContent?.user?.organization}
          lead={pageContent?.lead}
          pageName={pageContent?.content?.details?.title}
        />
        <Container component="main" className={classes.main} maxWidth="md">
          <div className={classes.contentSection}>
            <ViewerErrorBoundary>
            <div className={classes.viewerWrapper}>
  {files.length > 1 && (
    <>
      <button
        className={classes.navButton}
        style={{
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={() => setActiveIndex((prev) => Math.max(0, prev - 1))}
        disabled={activeIndex === 0}
      >
        <ChevronLeft size={24} />
      </button>

      <button
        className={classes.navButton}
        style={{
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={() => setActiveIndex((prev) => Math.min(files.length - 1, prev + 1))}
        disabled={activeIndex === files.length - 1}
      >
        <ChevronRight size={24} />
      </button>
    </>
  )}
  {files[activeIndex] && (
    <div 
      onTouchStart={e => setTouchStart(e.touches[0].clientX)}
      onTouchMove={e => setTouchEnd(e.touches[0].clientX)}
      onTouchEnd={() => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        
        if (isLeftSwipe && activeIndex < files.length - 1) {
          setActiveIndex(prev => prev + 1);
        }
        if (isRightSwipe && activeIndex > 0) {
          setActiveIndex(prev => prev - 1);
        }
        
        setTouchEnd(null);
        setTouchStart(null);
      }}
      style={{ width: '100%', height: '100%' }}
    >
      <FileViewer file={files[activeIndex]} />
    </div>
  )}
</div>
            </ViewerErrorBoundary>

            {/* Thumbnails */}
            {files.length > 1 && (
  <div className={classes.thumbnailSection}>
    <div 
      className={classes.thumbnailStrip}
      onTouchStart={e => setTouchStart(e.touches[0].clientX)}
      onTouchMove={e => {
        setTouchEnd(e.touches[0].clientX);
        // Prevent page scroll during swipe
        e.preventDefault();
      }}
      onTouchEnd={() => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const element = document.querySelector(`.${classes.thumbnailStrip}`);
        if (element) {
          element.scrollBy({
            left: distance,
            behavior: 'smooth'
          });
        }
        
        setTouchEnd(null);
        setTouchStart(null);
      }}
    >
      {files.map((file, index) => (
        <div
          key={index}
          data-index={index}
          className={`${classes.thumbnail} ${
            index === activeIndex ? classes.thumbnailActive : ""
          }`}
          onClick={() => setActiveIndex(index)}
        >
          {file.uri.match(/\.(jpg|jpeg|png|gif)$/i) ? (
            <img
              src={file.uri}
              alt={`thumbnail ${index}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : file.uri.match(/\.(mp4|webm)$/i) ? (
            <Film size={30} />
          ) : (
            <FileText size={30} />
          )}
        </div>
      ))}
    </div>
  </div>
)}

            {/* Description Section */}
            {pageContent?.content?.details?.description && (
              <div className={classes.descriptionSection}>
                <p>{pageContent.content.details.description}</p>
              </div>
            )}

            {/* User info section */}
            {pageContent?.user && (
              <div className={classes.footerContainer}>
                <Footer
                  userData={pageContent.user}
                  pageTitle={pageContent?.content?.details?.title}
                  digitalCard={pageContent?.digitalCard}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </LoadingOverlay>
  );
}

export default SharedFile;
