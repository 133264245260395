import React, { useEffect, useState, useCallback, memo, useMemo } from "react";
import LoadingOverlay from "react-loading-overlay";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import queryString from "query-string";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FileText, Film, ChevronLeft, ChevronRight } from "lucide-react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import debounce from "lodash/debounce";

import { getContent } from "../constants/ApiCall";

// Constants
const BUCKET_URL = "https://all-user-version-4.s3.us-east-1.amazonaws.com/";

// Utility Functions
const getFileType = (uri) => {
  if (!uri) return "unknown";

  // YouTube check first to handle URLs properly
  if (
    uri.match(
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|&v=))([^#&?]*)/
    )
  ) {
    return "youtube";
  }

  const extension = uri.split(".").pop().toLowerCase();

  if (["jpg", "jpeg", "png", "gif"].includes(extension)) return "image";
  if (["mp4", "webm"].includes(extension)) return "video";
  if (extension === "pdf") return "pdf";

  return "unknown";
};

const processVideoUrls = (videoUrl) => {
  if (!videoUrl) return [];
  return videoUrl
    .split(",")
    .map((url) => {
      const videoId = url.match(
        /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|&v=))([^#&?]*)/
      )?.[1];
      return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
    })
    .filter(Boolean);
};

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    background: "#FFF",
  },
  main: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  contentSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  viewerWrapper: {
    position: "relative",
    width: "100%",
    background: "#EBEFF4",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    overflow: "hidden",
  },
  viewerContainer: {
    position: "relative",
    flex: 1,
    width: "100%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mediaContent: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  youtubeContainer: {
    width: "100%",
    height: "100%",
    aspectRatio: "16/9",
    background: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  pdfWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  thumbnailSection: {
    background: "#EBEFF4",
    padding: "10px 0",
    borderRadius: "8px",
    overflow: "hidden",
  },
  thumbnailStrip: {
    display: "flex",
    overflowX: "auto",
    gap: "8px",
    padding: "0 16px",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "2px",
    },
  },
  progressBar: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "3px",
    backgroundColor: theme.palette.primary.main,
    transition: "width 0.2s",
    zIndex: 1100,
  },
  thumbnail: {
    width: "80px",
    height: "80px",
    flexShrink: 0,
    border: "2px solid transparent",
    borderRadius: "4px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    cursor: "pointer",
    transition: "border-color 0.2s",
  },
  thumbnailActive: {
    border: "2px solid #4a90e2",
  },
  navButton: {
    position: "absolute",
    width: "40px",
    height: "40px",
    background: "rgba(0, 0, 0, 0.5)",
    border: "none",
    borderRadius: "50%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 10,
    transition: "opacity 0.2s",
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
    "&:hover": {
      opacity: 1,
    },
  },
  descriptionSection: {
    padding: "20px",
    background: "#EBEFF4",
    borderRadius: "8px",
    "& h3": {
      margin: 0,
      marginBottom: "12px",
      fontSize: "18px",
      fontWeight: "bold",
      color: "#333",
    },
    "& p": {
      margin: 0,
      fontSize: "14px",
      lineHeight: "1.6",
      whiteSpace: "pre-line",
    },
  },
  mapSection: {
    height: "400px",
    borderRadius: "8px",
    overflow: "hidden",
    marginTop: "12px",
  },
  videoSection: {
    marginTop: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  videoWrapper: {
    width: "100%",
    position: "relative",
    paddingTop: "56.25%",
    backgroundColor: "#000",
    borderRadius: "8px",
    overflow: "hidden",
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
    },
  },
}));

// FileViewer Component
const FileViewer = ({ file }) => {
  const classes = useStyles();
  const fileType = getFileType(file.uri);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleMediaError = useCallback(() => {
    console.error(`Media load failed: ${file.uri}`);
    setError(`Failed to load: ${file.uri}`);
  }, [file.uri]);
  if (error) return <div>Failed to load media</div>;
  const renderContent = () => {
    switch (fileType) {
      case "image":
        return (
          <img
            loading="lazy"
            src={file.uri}
            alt="content"
            className={classes.mediaContent}
          />
        );

      case "video":
        return (
          <video controls className={classes.mediaContent}>
            <source
              src={file.uri}
              type={`video/${file.uri.split(".").pop()}`}
            />
          </video>
        );

      case "youtube":
        const videoId = file.uri.match(
          /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|&v=))([^#&?]*)/
        )?.[1];
        return videoId ? (
          <div className={classes.youtubeContainer}>
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              style={{ width: "100%", height: "100%" }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : null;

      case "pdf":
        return (
          <div className={classes.pdfWrapper}>
            <DocViewer
              documents={[file]}
              pluginRenderers={DocViewerRenderers}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                },
                pdfZoom: {
                  defaultZoom: 1,
                  zoomJump: 0.2,
                },
                pdfVerticalScrollByDefault: true,
                maxZoom: 3,
                minZoom: 0.5,
              }}
            />
          </div>
        );

      default:
        return <div>Unsupported file type</div>;
    }
  };

  return <div className={classes.viewerContainer}>{renderContent()}</div>;
};

// Main SharedFile Component
function SharedFile({ match, location }) {
  const classes = useStyles();
  const { uniqueLinkId } = match.params;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [pageContent, setPageContent] = useState({});

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  // Keyboard navigation
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        setActiveIndex((prev) => Math.max(0, prev - 1));
      } else if (e.key === "ArrowRight") {
        setActiveIndex((prev) => Math.min(files.length - 1, prev + 1));
      }
    },
    [files.length]
  );

  // Setup keyboard navigation
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  // Auto-scroll thumbnail
  useEffect(() => {
    try {
      const thumbnail = document.querySelector(`[data-index="${activeIndex}"]`);
      if (thumbnail) {
        thumbnail.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }
    } catch (err) {}
  }, [activeIndex]);

  // Add effect for map initialization
  useEffect(() => {
    const location = pageContent?.content?.details?.location;
    if (location && window.google) {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: location.latitude, lng: location.longitude },
        zoom: 15,
      });

      new window.google.maps.Marker({
        position: { lat: location.latitude, lng: location.longitude },
        map: map,
      });
    }
  }, [pageContent?.content?.details?.location]);

  // Load content
  useEffect(() => {
    let mounted = true;

    const loadContent = async () => {
      try {
        const queryParams = queryString.parse(location.search);
        const response = await getContent(uniqueLinkId, queryParams.u || "");

        if (!mounted) return;

        if (response?.data?.content?.files) {
          const myFiles = response.data.content.files.map((file) => ({
            uri: BUCKET_URL + file.filePath,
          }));

          setFiles(myFiles);
          setPageContent(response.data);
        }
      } catch (error) {
        if (mounted) setError("Unable to load content");
      } finally {
        if (mounted) setLoading(false);
      }
    };

    loadContent();
    return () => {
      mounted = false;
    };
  }, [uniqueLinkId, location.search]);

  // Progress Bar Component
  const ProgressBar = memo(() => {
    const classes = useStyles();
    const [progress, setProgress] = useState(0);

    const calculateProgress = useCallback(() => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      const progress = (scrollTop / (scrollHeight - clientHeight)) * 100;
      setProgress(Math.min(100, Math.max(0, progress)));
    }, []);

    const debouncedCalculateProgress = useMemo(
      () => debounce(calculateProgress, 100),
      [calculateProgress]
    );

    useEffect(() => {
      window.addEventListener("scroll", debouncedCalculateProgress);
      window.addEventListener("resize", debouncedCalculateProgress);
      calculateProgress();

      return () => {
        debouncedCalculateProgress.cancel();
        window.removeEventListener("scroll", debouncedCalculateProgress);
        window.removeEventListener("resize", debouncedCalculateProgress);
      };
    }, [debouncedCalculateProgress]);

    return (
      <div className={classes.progressBar} style={{ width: `${progress}%` }} />
    );
  });

  // Render thumbnail
  const renderThumbnail = (file, index) => {
    const fileType = getFileType(file.uri);
    const isActive = index === activeIndex;

    return (
      <div
        key={file.uri}
        data-index={index}
        className={`${classes.thumbnail} ${
          isActive ? classes.thumbnailActive : ""
        }`}
        onClick={() => setActiveIndex(index)}
      >
        {fileType === "image" ? (
          <img
            loading="lazy"
            src={file.uri}
            alt={`thumbnail ${index}`}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {fileType === "video" || fileType === "youtube" ? (
              <Film size={24} />
            ) : (
              <FileText size={24} />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Loading files..."
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
        },
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.8)",
        }),
      }}
    >
      <div className={classes.root}>
        <ProgressBar />
        <Header
          organization={pageContent?.user?.organization}
          lead={pageContent?.lead}
          pageName={pageContent?.content?.details?.title}
        />

        <Container component="main" className={classes.main} maxWidth="md">
          <div className={classes.contentSection}>
            <div className={classes.viewerWrapper}>
              {files.length > 1 && (
                <>
                   <button
        className={classes.navButton}
        style={{
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={() =>
          setActiveIndex((prev) => Math.max(0, prev - 1))
        }
        disabled={activeIndex === 0}
      >
        <ChevronLeft size={24} />
      </button>

                  <button
        className={classes.navButton}
        style={{
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={() =>
          setActiveIndex((prev) =>
            Math.min(files.length - 1, prev + 1)
          )
        }
        disabled={activeIndex === files.length - 1}
      >
        <ChevronRight size={24} />
      </button>
    </>
  )}

{files[activeIndex] && (
    <div 
      onTouchStart={e => setTouchStart(e.touches[0].clientX)}
      onTouchMove={e => setTouchEnd(e.touches[0].clientX)}
      onTouchEnd={() => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        
        if (isLeftSwipe && activeIndex < files.length - 1) {
          setActiveIndex(prev => prev + 1);
        }
        if (isRightSwipe && activeIndex > 0) {
          setActiveIndex(prev => prev - 1);
        }
        
        setTouchEnd(null);
        setTouchStart(null);
      }}
      style={{ width: '100%', height: '100%' }}
    >
      <FileViewer file={files[activeIndex]} />
    </div>
  )}
</div>


            {files.length > 1 && (
  <div className={classes.thumbnailSection}>
    <div 
      className={classes.thumbnailStrip}
      onTouchStart={e => setTouchStart(e.touches[0].clientX)}
      onTouchMove={e => {
        setTouchEnd(e.touches[0].clientX);
        // Prevent page scroll during swipe
        e.preventDefault();
      }}
      onTouchEnd={() => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const element = document.querySelector(`.${classes.thumbnailStrip}`);
        if (element) {
          element.scrollBy({
            left: distance,
            behavior: 'smooth'
          });
        }
        
        setTouchEnd(null);
        setTouchStart(null);
      }}
    >
      {files.map((file, index) => renderThumbnail(file, index))}
    </div>
  </div>
)}

            {pageContent?.content?.details?.description && (
              <div className={classes.descriptionSection}>
                <h3>Details</h3>
                <p>{pageContent.content.details.description}</p>
              </div>
            )}
          </div>

          {/* Videos Section */}
          {pageContent?.content?.details?.videoUrl && (
            <div className={classes.videoSection}>
              {pageContent.content.details.videoUrl
                .split(",")
                .map((url, index) => {
                  const videoId = url.match(
                    /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|&v=))([^#&?]*)/
                  )?.[1];
                  return (
                    videoId && (
                      <div key={index} className={classes.videoWrapper}>
                        <iframe
                          src={`https://www.youtube.com/embed/${videoId}`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    )
                  );
                })}
            </div>
          )}

          {/* Map Section */}
          {pageContent?.content?.details?.location && (
            <div className={classes.descriptionSection}>
              <div className={classes.titleContainer}>
                <h3>Address</h3>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${pageContent.content.details.location.latitude},${pageContent.content.details.location.longitude}`}
                  className={classes.addressLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Directions →
                </a>
              </div>
              <div className={classes.mapSection}>
                <div id="map" style={{ width: "100%", height: "100%" }} />
              </div>
            </div>
          )}

          {pageContent?.user && (
            <Footer
              userData={pageContent.user}
              pageTitle={pageContent?.content?.details?.title}
              digitalCard={pageContent?.digitalCard}
            />
          )}
        </Container>
      </div>
    </LoadingOverlay>
  );
}

export default SharedFile;
